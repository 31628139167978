import React from 'react';
import "../assets/css/main.css";
import heroImage from "../assets/img/hero-img.png";


function HeroComponent() {
  return (
    <section id="hero" className="hero section">

    <div className="container">
      <div className="row gy-4">
        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1 className="hero-header-conten">Grow your business with Cleonatech</h1>
          <p className="hero-description-conten">We are a team of skilled individuals dedicated to crafting a state-of-the-art technological solution.</p>
          <div className="d-flex">
            <a href="#services" className="btn-get-started">Get Started</a>
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img">
          <img src={heroImage} className="img-fluid animated" alt=""/>
        </div>
      </div>
    </div>

  </section>
  );
}

export default HeroComponent;
