import React from "react";
import "../assets/css/main.css";


function ServicesComponent() {
    return (
        <section id="services" class="services section">

       
        <div class="container section-title" data-aos="fade-up">
          <h2>Services</h2>
          <p>Transform your business with one of the best product development firm</p>
        </div>
  
        <div class="container">
  
          <div class="row gy-4">
  
            <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="100">
              <div class="service-item position-relative">
                <i class="bi bi-activity"></i>
                <h4><a href="?#" class="stretched-link">UI/UX Design</a></h4>
                <p>Our user experience design service employs a meticulous process to ensure we fulfill your requirements and deliver exceptional outcomes.</p>
              </div>
            </div>
  
            <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="200">
              <div class="service-item position-relative">
                <i class="bi bi-bounding-box-circles"></i>
                <h4><a href="?#" class="stretched-link">Web Development</a></h4>
                <p>We offer a broad spectrum of web development services to fully tap into the capabilities offered by modern web technologies</p>
              </div>
            </div>
  
            <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="300">
              <div class="service-item position-relative">
                <i class="bi bi-calendar4-week"></i>
                <h4><a href="?#" class="stretched-link">Mobile App Development</a></h4>
                <p>Our entire mobile app development process is aligned to meet the timelines and focus on accelerating change by employing a combination of development approaches.</p>
              </div>
            </div>
  
            <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="400">
              <div class="service-item position-relative">
                <i class="bi bi-broadcast"></i>
                <h4><a href="?#" class="stretched-link">AI</a></h4>
                <p>As an AI software company, we help companies implement AI into business processes strategically and effectively.</p>
              </div>
            </div>
  
          </div>
  
        </div>
  
      </section>
    );
  }
  
  export default ServicesComponent;