import React from 'react';
import "../assets/css/main.css";


function AboutUsComponent() {
  return (
   
    <section id="about" className="about section">
      <div className="container section-title" data-aos="fade-up">
        <h2>About Us</h2>
        <p>Driving innovation through cutting-edge technology solutions</p>
      </div>

      <div className="container">

        <div className="row gy-5">

       

          <div className="col-xl-12" data-aos="fade-up" data-aos-delay="200">

          <div className="content col-xl-12 d-flex flex-column" data-aos="fade-up" data-aos-delay="100">
            <h3>Leading the Future of Tech Solutions</h3>
            <p>
            At Cleonatech, we specialize in creating innovative and reliable tech solutions tailored to meet the unique needs of our clients. Our dedicated team of experts leverages the latest technologies to drive digital transformation and deliver exceptional results.
            </p>
            {/* <a href="?#" className="about-btn align-self-center align-self-xl-start"><span>About us</span> <i className="bi bi-chevron-right"></i></a> */}
          </div>


            <div className="row gy-4">

              <div className="col-md-6 icon-box position-relative">
                <i className="bi bi-briefcase"></i>
                <h4><a href="?#" className="stretched-link">Innovative Solutions</a></h4>
                <p>Our team develops custom solutions that address complex challenges, ensuring seamless integration and optimal performance.</p>
              </div>

              <div className="col-md-6 icon-box position-relative">
                <i className="bi bi-gem"></i>
                <h4><a href="?#" className="stretched-link">Exceptional Quality</a></h4>
                <p>We prioritize quality in all our projects, delivering robust and scalable solutions that exceed expectations.</p>
              </div>

              <div className="col-md-6 icon-box position-relative">
                <i className="bi bi-broadcast"></i>
                <h4><a href="?#" className="stretched-link">Expert Team</a></h4>
                <p>Our skilled professionals are committed to delivering excellence, leveraging their expertise to drive innovation and success.</p>
              </div>

              <div className="col-md-6 icon-box position-relative">
                <i className="bi bi-easel"></i>
                <h4><a href="?#" className="stretched-link">Comprehensive Support</a></h4>
                <p>Our support services ensure that your solutions remain up-to-date and perform at their best, with continuous monitoring and maintenance.</p>
              </div>

            </div>
          </div>

        </div>

      </div>

    </section>

    
  );
}

export default AboutUsComponent;
