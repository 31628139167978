import React from 'react';
import "../assets/css/main.css";
import angular from "../assets/tech/angular.png";
import node from "../assets/tech/node-js.png";
import springboot from "../assets/tech/spring-boot.png";
import reactImg from "../assets/tech/react.png";
import mongoDB from "../assets/tech/mongoDB.png";
import flutter from "../assets/tech/flutter.png";
import swift from "../assets/tech/swift.png";
import laravel from "../assets/tech/Laravel.png";
import vue from "../assets/tech/Vue.png";
import aws from "../assets/tech/aws.png";
import azure from "../assets/tech/azure.png";
import digitalOcean from "../assets/tech/DigitalOcean.png";



function FeaturesComponent() {
  return (
    <section id="features" className="features section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Techstack</h2>
        <p>Integrating Cutting Edge Technology into Your Product</p>
      </div>

      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="100">
            <div className="features-item">
              <img src={angular} alt="" style={{width:30, height:30}} /> &nbsp;&nbsp;
              <h3><span className="stretched-link">Angular</span></h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="200">
            <div className="features-item">
            <img src={node} alt="" style={{width:28, height:28}} /> &nbsp;&nbsp;
              <h3><span className="stretched-link">Node Js</span></h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="300">
            <div className="features-item">
            <img src={springboot} alt="" style={{width:28, height:28}} /> &nbsp;&nbsp;
              <h3><span className="stretched-link">Spring Boot</span></h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="400">
            <div className="features-item">
            <img src={reactImg} alt="" style={{width:28, height:28}} /> &nbsp;&nbsp;
              <h3><span className="stretched-link">React</span></h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="500">
            <div className="features-item">
            <img src={mongoDB} alt="" style={{width:28, height:28}} /> &nbsp;&nbsp;
              <h3><span className="stretched-link">Mongo DB</span></h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="600">
            <div className="features-item">
            <img src={flutter} alt="" style={{width:28, height:28}} /> &nbsp;&nbsp;
              <h3><span className="stretched-link">Flutter</span></h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="700">
            <div className="features-item">
            <img src={swift} alt="" style={{width:28, height:28}} /> &nbsp;&nbsp;
              <h3><span className="stretched-link">Swift</span></h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="800">
            <div className="features-item">
            <img src={laravel} alt="" style={{width:28, height:28}} /> &nbsp;&nbsp;
              <h3><span className="stretched-link">Laravel</span></h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="900">
            <div className="features-item">
            <img src={vue} alt="" style={{width:28, height:28}} /> &nbsp;&nbsp;
              <h3><span className="stretched-link">Vue.js</span></h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="1000">
            <div className="features-item">
            <img src={aws} alt="" style={{width:28, height:28}} /> &nbsp;&nbsp;
              <h3><span className="stretched-link">AWS</span></h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="1100">
            <div className="features-item">
            <img src={azure} alt="" style={{width:28, height:28}} /> &nbsp;&nbsp;
              <h3><span className="stretched-link">Azure</span></h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-4" data-aos="fade-up" data-aos-delay="1200">
            <div className="features-item">
            <img src={digitalOcean} alt="" style={{width:28, height:28}} /> &nbsp;&nbsp;
              <h3><span className="stretched-link">Digitalocean</span></h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesComponent;
