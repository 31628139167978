import './App.css';
import NavbarComponent from './components/NavbarComponent';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import FooterComponent from './components/FooterComponent';



// function App() {
  const App = () => {
    useEffect(() => {
      AOS.init({
        duration: 600,
        easing: 'ease-in-out',
        once: true,
        mirror: false
      });
    }, []);

    return (
    <div className="App">
      <NavbarComponent/>
      <Router>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
    <FooterComponent/>
    </div>
  );
}

export default App;
