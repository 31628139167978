import React from "react";
import "../assets/css/main.css";

function ContactComponent() {
  return (
    <section id="contact" className="contact section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Get in Touch</h2>
        <p>
          Looking for a partner to help you with your business? We specialize in
          product design, web development, and much more. Just fill the form and
          our team will get in touch with you.
        </p>
      </div>

      <div
        className="container position-relative contact-spacing"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div className="row">
          <div className="col-12 col-md-4">
            <div
              className="info-item d-flex"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <a href="https://maps.app.goo.gl/yDfZytXr1k8CsXfS9"><i className="bi bi-geo-alt flex-shrink-0"></i></a>
              <div>
                <h3>Address</h3>
                <p>73, Sudbury Rd, Ashland, MA 01721</p>
              </div>
            </div>
          <div class="space-dive"></div>

          </div>

          <div className="col-12 col-md-4">
            <div
              className="info-item d-flex"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <a href="tel:+16176868789"><i className="bi bi-telephone flex-shrink-0"></i></a>
              <div>
                <h3 >Call Us</h3>
                <p>(+1) 617 686 8789</p>
              </div>
            </div>
            <div class="space-dive"></div>
          </div>
          
      
          <div className="col-12 col-md-4">
            <div
              className="info-item d-flex"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <a href="mailto:info@cleonatech.com"><i className="bi bi-envelope flex-shrink-0"></i></a>
              <div>
                <h3>Email Us</h3>
                <p>info@cleonatech.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactComponent;
