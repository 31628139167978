import React, { useEffect } from 'react';
import "../assets/css/main.css";

import mainLogo from "../assets/img/logo.png";

// No need to import nav.js as we will include the functionality in this component

const NavbarComponent = () => {
  useEffect(() => {
    const mobileNavToggleBtn = document.querySelector('.mobile-nav-toggle');

    const mobileNavToggle = () => {
      document.body.classList.toggle('mobile-nav-active');
      mobileNavToggleBtn.classList.toggle('bi-list');
      mobileNavToggleBtn.classList.toggle('bi-x');
    };

    mobileNavToggleBtn.addEventListener('click', mobileNavToggle);

    const navLinks = document.querySelectorAll('#navmenu a');
    navLinks.forEach(navLink => {
      navLink.addEventListener('click', () => {
        if (document.body.classList.contains('mobile-nav-active')) {
          mobileNavToggle();
        }
      });
    });

    // Cleanup event listeners on component unmount
    return () => {
      mobileNavToggleBtn.removeEventListener('click', mobileNavToggle);
      navLinks.forEach(navLink => {
        navLink.removeEventListener('click', mobileNavToggle);
      });
    };
  }, []);

  return (
    <div id="header" className="header d-flex align-items-center sticky-top">
      <div className="container-fluid container-xl position-relative d-flex align-items-center">
        <a href="/" className="logo d-flex align-items-center me-auto">
          <img src={mainLogo} alt="" srcset="" />
          <h1 className="sitename">Cleonatech</h1>
        </a>

        <nav id="navmenu" className="navmenu">
          <ul>
            <li><a href="/" className="active">Home<br/></a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#services">Services</a></li>
            {/* <li><a href="#team">Team</a></li> */}
            {/* <li><a href="#contact">Contact</a></li> */}
          </ul>
          <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
        </nav>

        <a className="btn-getstarted" href="tel:+16176868789">Contact</a>
      </div>
    </div>
  );
};

export default NavbarComponent;
