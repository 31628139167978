// import React from 'react';
import "../assets/css/main.css";
import statusImage from '../assets/img/stats-img.svg';

import React, { useEffect } from 'react';
import PureCounter from "@srexi/purecounterjs";
import 'aos/dist/aos.css';
import AOS from 'aos';

const StatusComponent = () => {
  useEffect(() => {
    new PureCounter();
    AOS.init({
      duration: 1000,
      delay: 100,
    });
  }, []);

  return (
    <section id="stats" className="stats section">
      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4 align-items-center">
          <div className="col-lg-5">
            <img src={statusImage} alt="" className="img-fluid" />
          </div>
          <div className="col-lg-7">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="stats-item d-flex">
                  <i className="bi bi-emoji-smile flex-shrink-0"></i>
                  <div>
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="42"
                      data-purecounter-duration="1"
                      className="purecounter"
                    ></span>
                    <p>
                      <strong>Happy Clients</strong> 
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="stats-item d-flex">
                  <i className="bi bi-journal-richtext flex-shrink-0"></i>
                  <div>
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="45"
                      data-purecounter-duration="1"
                      className="purecounter"
                    ></span>
                    <p>
                      <strong>Projects</strong> 
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="stats-item d-flex">
                  <i className="bi bi-headset flex-shrink-0"></i>
                  <div>
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="120"
                      data-purecounter-duration="1"
                      className="purecounter"
                    ></span>
                    <p>
                      <strong>Hours Of Working</strong> 
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="stats-item d-flex">
                  <i className="bi bi-people flex-shrink-0"></i>
                  <div>
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="8"
                      data-purecounter-duration="1"
                      className="purecounter"
                    ></span>
                    <p>
                      <strong>Hard Workers</strong> 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatusComponent;
