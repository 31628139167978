import React from 'react';
import HeroComponent from '../components/HeroComponent';
// import ClientsComponent from '../components/ClientsComponent';
import AboutUsComponent from '../components/AboutUsComponent';
import StatusComponent from '../components/StatusComponent'
import ServicesComponent from '../components/ServicesComponent';
import FeaturesComponent from '../components/FeaturesComponent';
import TestimonialsComponent from '../components/TestimonialsComponent';
// import TeamComponent from '../components/TeamComponent';
import ContactComponent from '../components/ContactComponent';

function Home() {
  return (
   <div>
        <HeroComponent />
        {/* <ClientsComponent /> */}
        <AboutUsComponent/>
        <StatusComponent/>
        <ServicesComponent/>
        <FeaturesComponent/>
        <TestimonialsComponent/>
        {/* <TeamComponent/> */}
        <ContactComponent/>
    </div>

  );
}

export default Home;
