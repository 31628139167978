import React from "react";
import "../assets/css/main.css";

function FooterComponent() {
    return (
        <footer id="footer" class="footer">

        <div class="container">
          <div class="copyright text-center ">
            <p>© <span>Copyright</span> <strong class="px-1 sitename">Cleonatech</strong> <span>All Rights Reserved</span></p>
          </div>
          {/* <div class="social-links d-flex justify-content-center">
            <a href="?#"><i class="bi bi-twitter-x"></i></a>
            <a href="?#"><i class="bi bi-facebook"></i></a>
            <a href="?#"><i class="bi bi-instagram"></i></a>
            <a href="?#"><i class="bi bi-linkedin"></i></a>
          </div> */}
          
        </div>
    
      </footer>
    );
  }
  
  export default FooterComponent;